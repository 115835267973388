<template>
  <div class="container-fluid rounded bg-white py-4">
    <div class="sub-modal">
      <div class="row mb-3">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'initiative' })" />
        </div>
      </div>

      <div class="m-0 mb-5 justify-content-between shdow">
        <div class="col-12 d-flex align-items-center justify-content-between">
          <p class="text-secondary h1 mb-0">
            <span>Modifier initiative n°1</span>
          </p>

          <b-button variant="secondary">Confirmer</b-button>
        </div>

        <div class="col-12">
          <hr class="border my-3" />
        </div>

        <div class="row p-0 m-0">
          <div class="col-12" style="flex: 1; margin-right: 20px">
            <b-form class="row">
              <div class="col-md-6 col-12">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Libellé initiative:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="Libellé initiative"
                      required
                    ></b-form-input>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Date de création:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-datepicker
                      disabled
                      :value="new Date()"
                    ></b-form-datepicker>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Sponsor:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="Sponsor"
                      required
                    ></b-form-input>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Statut:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="Statut"
                      required
                    ></b-form-input>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>FdR:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="FDR"
                      required
                    ></b-form-input>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Patrimoine:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="Patrimoine"
                      required
                    ></b-form-input>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Type initiative:</label>
                  </div>
                  <div class="col-md-8">
                    <select class="custom-select">
                      <option selected></option>
                      <option value="1">APPLI</option>
                      <option value="2">INFRA</option>
                      <option value="3">METIER / ORGA</option>
                      <option value="3">AUTRE</option>
                    </select>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Phase initiative:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="Phase initiative"
                      required
                    ></b-form-input>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Description:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      type="text"
                      placeholder="Description"
                      required
                    ></b-form-input>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="mb-0">Avancement:</label>
                  </div>
                  <div class="col-md-8">
                    <b-progress :max="100" height="15px">
                      <b-progress-bar
                        :value="70"
                        :label="`${70}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <b-tabs class="tab-solid tab-solid-primary">
        <b-tab title="Finance" active>
          <div class="row justify-content-between p-0 m-0 flex-wrap">
            <div class="col-sm-6 col-12 mb-2 pl-sm-0">
              <div class="m-0 p-0 shdow">
                <div class="hdr py-2 text-white rounded-top mb-3">
                  Situation actuelle: 10/11/2021
                </div>
                <div class="p-4">
                  <p>Budget initiative: 1000€</p>
                  <p>Budget FdR: 8000€</p>
                  <br />
                  <br />
                  <p>Dernier ajustement: 10/11/2021</p>
                  <div class="row m-0 p-0 align-items-center">
                    <span class="mr-2">Ajustement</span>

                    <b-form-input
                      placeholder="Ajuster"
                      class="w-25 mr-2 border border-gray"
                      size="sm"
                    ></b-form-input>

                    <b-button size="sm" class="bg-blue" variant="info"
                      >Réviser</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-12 mb-4 pr-sm-0">
              <div style="position: relative" class="m-0 p-0 shdow">
                <div class="hdr py-2 text-white rounded-top mb-3">
                  Suivi conso initiative
                </div>
                <div class="p-2">
                  <div
                    class="row p-0 m-0 align-items-center py-2 px-3"
                    style="min-height: 250px"
                  >
                    <PieChart style="width: 100%" :options="pieOptions" />
                    <p>Avancement:</p>
                    <div
                      style="
                        margin-top: 15px;
                        background: #ededed;
                        height: 20px;
                        width: 100%;
                        position: relative;
                        border-radius: 20px;
                        overflow: hidden;
                      "
                    >
                      <div
                        style="
                          width: 90%;
                          height: 100%;
                          background: #1dbf4d;
                          position: absolute;
                          top: 0;
                        "
                      ></div>
                      <p
                        style="
                          position: relative;
                          color: black;
                          font-size: 15px;
                          text-align: center;
                        "
                      >
                        90%
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <PieChart style="width: 100%;" :options="pieOptions" /> -->
              </div>
            </div>
          </div>
          <div class="hdr py-2 text-white rounded-top mb-3">Historique</div>
          <div class="table-responsive mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Date révision</th>
                  <th>Resp fin</th>
                  <th>Budget initiative</th>
                  <th>Montant ajusté</th>
                  <th>Budget initiative révisé</th>
                  <th>Tendance</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>10/11/2021</td>
                  <td>
                    <b-avatar
                      v-b-tooltip.hover="'Steve Ewore'"
                      variant="primary"
                      size="2rem"
                      src="https://source.unsplash.com/random/150x150"
                      class="mr-1"
                    ></b-avatar>
                    S.E
                  </td>
                  <td>10.000€</td>
                  <td>-2.000€</td>
                  <td>8.000€</td>
                  <td>
                    <div class="p-0 m-0 row">
                      <i
                        style="font-size: 20px; color: red"
                        class="mdi mdi-menu-down"
                      ></i>
                      <p>-20%</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Référents">
          <div class="row p-0 m-0">
            <div class="col-12 mb-3 d-flex justify-content-end">
              <b-button variant="secondary">Valider</b-button>
            </div>
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                m-0
                p-0
                mr-4
              "
            >
              <div>
                <p>Sponsor</p>
              </div>
              <div
                style="
                  background: black;
                  height: 100px;
                  width: 100px;
                  border-radius: 50px;
                  position: relative;
                "
                class="
                  accnt-log
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <i
                  style="color: white; font-size: 40px"
                  class="mdi mdi-account-outline"
                ></i>
                <div style="position: absolute; bottom: -8px; right: 5px">
                  <i
                    style="
                      color: blue;
                      font-size: 25px;
                      background: white;
                      border-radius: 15px;
                    "
                    class="mdi mdi-plus-circle"
                  ></i>
                </div>
              </div>
              <div>
                <p>Steewe EWORE</p>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Les enjeux stratégiques">
          <div class="w-100 d-flex justify-content-end mb-3">
            <b-button
              variant="secondary"
              class="align-btn-table"
              @click="strategicChallenge.tableItems.push({ editMode: true })"
              >+ Affecter objectif
            </b-button>
          </div>
          <StrategicChallenge
            :tableItems="strategicChallenge.tableItems"
            @cancel="(itemIndex) => removeStrategyChallengeInputs(itemIndex)"
          />
        </b-tab>
        <b-tab title="Gains">
          <div class="w-100 d-flex align-items-center justify-content-end mb-3">
            <b-button
              variant="secondary"
              class="align-btn-table"
              @click="gains.tableItems.push({ editMode: true })"
              >+ Nouveau gain
            </b-button>
          </div>
          <Gains :tableItems="gains.tableItems" />
          >
        </b-tab>
        <b-tab title="Impacts">
          <div class="d-flex">
            <button
              @click="() => (initiative.selectedImpact = 0)"
              :class="`btn btn-fw ${
                initiative.selectedImpact == 0
                  ? 'btn-gradient-info'
                  : 'btn-outline-info'
              }`"
            >
              Impact SA
            </button>
            <button
              @click="() => (initiative.selectedImpact = 1)"
              type="button"
              :class="`btn btn-fw ${
                initiative.selectedImpact == 1
                  ? 'btn-gradient-info'
                  : 'btn-outline-info'
              }`"
            >
              Impact processus
            </button>
            <button
              @click="() => (initiative.selectedImpact = 2)"
              type="button"
              :class="`btn btn-fw ${
                initiative.selectedImpact == 2
                  ? 'btn-gradient-info'
                  : 'btn-outline-info'
              }`"
            >
              Impact data
            </button>
            <button
              @click="() => (initiative.selectedImpact = 3)"
              type="button"
              :class="`btn btn-fw ${
                initiative.selectedImpact == 3
                  ? 'btn-gradient-info'
                  : 'btn-outline-info'
              }`"
            >
              Impact infrastructure
            </button>
          </div>
          <div
            class="
              w-100
              d-flex
              flex-wrap
              align-items-center
              justify-content-end
              my-3
              align-btn-table
            "
          >
            <b-button variant="outline-secondary" class="mb-2" pill disabled
              >Plan de transformation</b-button
            >
            <b-button
              variant="secondary"
              @click="impacts.tableItems.push({ editMode: true })"
              >+ Nouvel impact
            </b-button>
          </div>
          <Impacts :tableItems="impacts.tableItems" />
          <TransformationPlan />
        </b-tab>
        <b-tab title="Cadres d'entreprise">
          <div>
            <div class="row mb-3">
              <div class="col-12 d-flex justify-content-end">
                <b-button
                  variant="secondary"
                  class="align-btn-table"
                  @click="addFrameModal = !addFrameModal"
                  >+ Ajouter un cadre
                </b-button>
              </div>
            </div>

            <CompanyFrame :tableItems="companyFrame.tableItems" />
          </div>
        </b-tab>
        <b-tab title="Planning">
          <div>
            <div class="d-flex mb-3">
              <dropdown-selection
                :text="initiative.planning.selectedView.text"
                :items="initiative.planning.viewOptions"
                @selected="(item) => (initiative.planning.selectedView = item)"
              />
            </div>

            <Planning
              :planning="initiative.planning"
              :newTasks="initiative.newTasks"
            />
          </div>
        </b-tab>

        <b-tab title="Tâches et livrables">
          <task-deliverable
            @showDeliverables="showDeliverablesModal = !showDeliverablesModal"
            :taskDeliverable="initiative.taskDeliverable"
          ></task-deliverable>
        </b-tab>

        <b-tab title="Comités et décisions">
          <div>
            <div class="d-flex justify-content-end mb-3">
              <b-button
                @click="
                  initiative.comiteDecision.createTacheModal =
                    !initiative.comiteDecision.createTacheModal
                "
                class="align-btn-table"
                >+ Nouvelle inscription</b-button
              >
            </div>

            <CommitteeDecision
              :tableItems="initiative.comiteDecision.tableItems"
              @searchGuests="searchGuestsModal = !searchGuestsModal"
            />
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <!-- Show deliverables modal -->
    <div v-if="showDeliverablesModal" to="modals-xyz-548">
      <modal @close="showDeliverablesModal = !showDeliverablesModal">
        <Deliverables />
      </modal>
    </div>

    <!-- Search guests -->
    <div to="modals-xyz-548" v-if="searchGuestsModal">
      <Modal @close="searchGuestsModal = !searchGuestsModal">
        <SearchGuests @close="searchGuestsModal = !searchGuestsModal" />
      </Modal>
    </div>

    <!-- Add Frame -->
    <div to="modals-xyz-548" v-if="addFrameModal">
      <Modal @close="addFrameModal = !addFrameModal">
        <add-frame @close="addFrameModal = !addFrameModal" />
      </Modal>
    </div>
  </div>
</template>

<script>
import DropdownSelection from "@/components/custom/DropdownSelection.vue";

import TaskDeliverable from "./components/TaskDeliverable.vue";
import Deliverables from "./components/Deliverables.vue";
import Modal from "@/components/custom/Modal.vue";
import PieChart from "@/components/charts/c3-chart/donut-c3.vue";

import StrategicChallenge from "./components/StrategicChallenge.vue";
import Gains from "./components/Gains.vue";
import Impacts from "./components/Impacts.vue";
import CompanyFrame from "./components/CompanyFrame.vue";
import Planning from "./components/Planning.vue";
import CommitteeDecision from "./components/CommitteeDecision.vue";
import SearchGuests from "./components/SearchGuests.vue";
import AddFrame from "./components/AddFrame.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import TransformationPlan from "@/components/custom/TransformationPlan.vue";

export default {
  name: "EditInitiative",
  components: {
    PieChart,
    DropdownSelection,
    TaskDeliverable,
    Deliverables,
    Modal,
    StrategicChallenge,
    Gains,
    Impacts,
    CompanyFrame,
    Planning,
    CommitteeDecision,
    SearchGuests,
    AddFrame,
    ModalActions,
    TransformationPlan,
  },
  data: () => ({
    searchGuestsModal: false,
    addFrameModal: false,
    impacts: {
      tableItems: [{ editMode: true, actions: ["edit", "delete"] }],
    },
    gains: {
      tableItems: [{ editMode: true, actions: ["validate", "cancel", "edit"] }],
    },
    strategicChallenge: {
      tableItems: [{ editMode: true }],
    },
    companyFrame: {
      tableItems: [],
    },
    initiative: {
      selectedImpact: 0,
      newTasks: [
        {
          title: "Tâche 1",
          type: "Plan actions",
          name: "Tâche one two three four five six seven",
          responsable: "S. E",
          id: "1",
          start: "2021-01-01",
          end: "2021-01-10",
          progress: 10,
          style: "text-truncate",
        },
        {
          title: "Tâche 2",
          type: "Plan actions",
          name: "Tâche one two three four five six seven",
          responsable: "S. E",
          id: "2",
          start: "2021-01-10",
          end: "2021-01-20",
          progress: 20,
          style: "text-truncate",
        },
        {
          title: "Tâche 3",
          type: "Plan actions",
          name: "Tâche one two three four five six seven",
          responsable: "S. E",
          id: "3",
          start: "2021-01-20",
          end: "2021-01-30",
          progress: 30,
          style: "text-truncate",
        },
        {
          title: "Tâche 4",
          type: "Plan actions",
          name: "Tâche one two three four five six seven",
          responsable: "S. E",
          id: "4",
          start: "2021-03-01",
          end: "2021-05-05",
          progress: 60,
          style: "text-truncate",
        },
        {
          title: "Tâche 4",
          type: "Plan actions",
          name: "Tâche one two three four five six seven",
          responsable: "S. E",
          id: "5",
          start: "2021-03-01",
          end: "2021-05-05",
          progress: 90,
          style: "text-truncate",
        },
      ],
      planning: {
        viewOptions: [
          { value: "tableau", text: "Tableau" },
          { value: "gantt", text: "Gantt" },
        ],
        selectedView: { value: "tableau", text: "Tableau" },

        tableItems: [
          {
            phase: "Etude",
            date_debut: "01/02/2021",
            date_fin: "10/05/2021",
            status: "Non demarré",
            actions: ["edit"],
          },
        ],
        tableFields: [
          { key: "phase", label: "Phase" },
          { key: "start_date", label: "Date début" },
          { key: "fin_date", label: "Date fin" },
          { key: "status", label: "Status" },
          { key: "actions", label: "" },
        ],
      },
      taskDeliverable: {
        tasks: [
          {
            title: "Tâche 1",
            id: "1",
            start: "2021-01-01",
            end: "2021-01-10",
            progress: 10,
            style: "text-truncate",
          },
          {
            title: "Tâche 2",
            id: "2",
            start: "2021-01-10",
            end: "2021-01-20",
            progress: 20,
            style: "text-truncate",
          },
          {
            title: "Tâche 3",
            id: "3",
            start: "2021-01-20",
            end: "2021-01-30",
            progress: 30,
            style: "text-truncate",
          },
        ],
        viewOptions: [
          { value: "tableau", text: "Tableau" },
          { value: "gantt", text: "Gantt" },
          { value: "kanban", text: "Kanban" },
        ],
        selectedView: { value: "tableau", text: "Tableau" },

        tableItems: [
          {
            phase: "Etude",
            activity: "Redaction Dossier Archi",
            activity_type: "",
            responsable: "",
            deliverables: "DossierT",
            deadline: "auto",
            end_prev_date: "",
            status: "",
            progress: 70,
            actions: ["show", "delete"],
          },
        ],
        tableFields: [
          { key: "phase", label: "Phase", sortable: true },
          { key: "activity", label: "Activité", sortable: true },
          { key: "activity_type", label: "Type activité", sortable: true },
          { key: "responsable", label: "Responsable", sortable: true },
          { key: "deliverables", label: "Livrables", sortable: true },
          { key: "deadline", label: "Statut échéance", sortable: true },
          {
            key: "end_prev_date",
            label: "Date fin prévisionnelle",
            sortable: true,
          },
          { key: "status", label: "Statut", sortable: true },
          { key: "progress", label: "Avancement", sortable: true },
          { key: "actions", label: "" },
        ],

        createTacheModal: false,
      },
      comiteDecision: {
        viewOptions: [
          { value: "tableau", text: "Tableau" },
          { value: "gantt", text: "Gantt" },
        ],
        selectedView: { value: "tableau", text: "Tableau" },

        tableItems: [
          {
            committee: "",
            committee_date: "",
            object_path: "",
            presentator: "",
            inscription_date: "",
            guests: "S. Ewore, J. page",
            delivery: "Dossier Architecture",
            decision: "Atourné",
            decision_comment: "",
            actions: ["edit", "delete"],
          },
        ],
        tableFields: [
          "Comité",
          "Date comité",
          "Object passage",
          "Presentateur",
          "Date inscription",
          "Invités",
          "Livrable",
          "Décision",
          "Commentaire décision",
          "Actions",
        ],
      },
      searchInvitedModal: false,
    },
    showDeliverablesModal: false,
    pieOptions: {
      data: {
        columns: [
          ["Consommé", 6000],
          ["Reste", 4000],
        ],
        type: "donut",
      },
      color: {
        pattern: ["#FFC000", "#072648"],
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 0,
      },
      bar: {
        width: {
          ratio: 0.7, // this makes bar width 50% of length between ticks
        },
      },
    },
  }),
  methods: {
    changeInputColor(e) {
      e.target.classList.remove("bg-danger", "bg-primary", "bg-success");
      switch (e.target.value) {
        case "create":
          e.target.classList.add("bg-success");
          break;
        case "evolution":
          e.target.classList.add("bg-primary");
          break;
        case "delete":
          e.target.classList.add("bg-danger");
          break;
        default:
          e.target.classList.remove("bg-danger", "bg-primary", "bg-success");
          break;
      }
    },
  },
};
</script>

<style scoped>
.top_gantt_controls {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 10px;
}

.header_sd_menu {
  height: 60px;
  background-color: white;
  width: 100%;
  border: 2px #f2edf3 solid;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}

.fl_1 {
  flex: 1;
}

.fl_2 {
  flex: 2;
}

.fl_3 {
  flex: 3;
}

.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    /* background-color: rgba(0, 0, 0, 0.377); */
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.sub-modal {
  background-color: white;
  padding: 20px 30px;
  width: 100%;
  height: 100%;
  position: relative;
}

.sb_mdl_ctrls {
  /* position: absolute; */
  /* top: -45px; */
  /* background-color: rebeccapurple; */
  height: 40px;
  /* right: 0px; */
  /* width: 100px; */
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}
</style>
